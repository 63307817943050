import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import MarkdownContent from "../components/MardownContent";
import ImageCarouselWithText from "../components/ImageCarouselWithText";

export const ProductPageTemplate = ({
  heading,
  description,
  hide,
  gridItems,
}) => (
  <div className="content">
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-7 is-offset-1">
              <h3 className="has-text-weight-semibold is-size-2">{heading}</h3>
              <MarkdownContent content={ description }/>
            </div>
          </div>
          {(() => {
            if (!hide) {
              return (
                <div className="columns">
                  <div className="column is-12">
                    <ImageCarouselWithText gridItems={ gridItems }/>
                  </div>
                </div>
              )
            }
          })()}
        </div>
      </div>
    </section>
  </div>
)

ProductPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  hide: PropTypes.bool,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const ProductPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ProductPageTemplate
        heading={frontmatter.heading}
        description={frontmatter.description}
        pricing={frontmatter.pricing}
        hide={frontmatter.hide}
        gridItems={ frontmatter.intro.blurbs || []}
      />
    </Layout>
  )
}

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ProductPage

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        heading
        description
        hide
        intro {
            blurbs {
                image {
                    childImageSharp {
                        fluid(maxWidth: 1200, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                text
                categoryid
            }
        }
      }
    }
  }
`
